import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Our Services at Strength Haven Gym
			</title>
			<meta name={"description"} content={"Transform Your Fitness Goals into Reality with Our Diverse Range of Services!"} />
			<meta property={"og:title"} content={"Our Services | Our Services at Strength Haven Gym"} />
			<meta property={"og:description"} content={"Transform Your Fitness Goals into Reality with Our Diverse Range of Services!"} />
			<meta property={"og:image"} content={"https://boltishes.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://boltishes.live/img/2413554.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://boltishes.live/img/2413554.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://boltishes.live/img/2413554.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://boltishes.live/img/2413554.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://boltishes.live/img/2413554.png"} />
			<meta name={"msapplication-TileImage"} content={"https://boltishes.live/img/2413554.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://boltishes.live/img/4.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline1" margin="0 0 12px 0">
					Our Services
				</Text>
				<Text font="--base">
					Discover the Gateway to Your Fitness Transformation – Explore the Diverse Range of Services at Strength Haven Gym! Offering a unique blend of personalized training, cutting-edge equipment, and a community-driven atmosphere, we're dedicated to helping you achieve your fitness goals in an environment that motivates and inspires.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				as="h2"
				margin="0px 0px 26px 0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="40%"
				md-width="100%"
			>
				Comprehensive Service Categories:
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="60%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				<Strong>
					Personal Training
				</Strong>
				<br />
				Individual Attention: Tailored fitness programs designed to meet your specific goals.
				<br />
				Expert Trainers: Experienced and certified professionals to guide you every step of the way.
				<br />
				<br />
				<Strong>
					Group Classes
				</Strong>
				<br />
				Dynamic Range: From high-energy cardio sessions to relaxing yoga and Pilates.
				<br />
				Community Feel: Enjoy the motivation and support of working out in a group setting.
				<br />
				<br />
				<Strong>
					Strength and Conditioning
				</Strong>
				<br />
				Advanced Equipment: State-of-the-art machines and free weights for all levels.
				<br />
				Functional Training: Techniques to improve strength, endurance, and agility.
				<br />
				<br />
				<Strong>
					Nutritional Guidance
				</Strong>
				<br />
				Personalized Plans: Customized nutrition advice to complement your fitness regimen.
				<br />
				Health and Wellness: Support for overall well-being and healthy lifestyle choices.
				<br />
				<br />
				<Strong>
					Wellness and Recovery
				</Strong>
				<br />
				Relaxation Zone: Dedicated spaces for relaxation and post-workout recovery.
				<br />
				Holistic Approach: Services include massage therapy and physiotherapy consultations.
				<br />
				<br />
				<Strong>
					Specialized Programs
				</Strong>
				<br />
				Diverse Offerings: From senior fitness programs to athletic training for sports enthusiasts.
				<br />
				Customizable Options: Tailored to cater to different fitness levels and preferences.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://boltishes.live/img/5.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://boltishes.live/img/6.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					For detailed information on our services or to schedule a visit, please contact us. Our team is ready to assist you in choosing the perfect program for your fitness journey.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});